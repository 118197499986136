import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/work/pomodoro-timer",
  "title": "Pomodoro Timer",
  "description": "Practice and curiosity in creating a twist with a playful look and feel to a classic pomodoro timer.",
  "workTags": ["UX/UI Design", "Web Development"],
  "tags": ["Website Design", "Development", "Vue", "React"],
  "coverPhoto": {
    "cloudinaryAssetData": true,
    "cloudName": "dmocgebcq",
    "publicId": "portfolio/preview-images/pomodoro-mockup-long_2x_rcsqw1.png",
    "originalHeight": 1248,
    "originalWidth": 2128,
    "defaultBase64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMMXG/8HwAEwAI0Bj1bnwAAAABJRU5ErkJggg==",
    "defaultTracedSVG": "data:image/svg+xml,%3Csvg%20height%3D%229999%22%20viewBox%3D%220%200%209999%209999%22%20width%3D%229999%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m0%200h9999v9999h-9999z%22%20fill%3D%22%23f9fafb%22%2F%3E%3C%2Fsvg%3E"
  },
  "work_jumbo_title": "A development challenge that turned into it's own app",
  "laptop_image": "https://res.cloudinary.com/dmocgebcq/image/upload/v1639095201/portfolio/laptop-images/pomodoro-laptop-mock_atfyzm.png",
  "role": "Designer/Developer",
  "deliverable": "Github Pages Site",
  "url": "https://vpugh.github.io/vue-pomodoro-timer/",
  "github": "https://github.com/vpugh/vue-pomodoro-timer",
  "client": "Self",
  "draft": false,
  "type": "work",
  "order": 2,
  "date": "2018-01-03T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`The Problem (The Challenge)`}</h3>
    <p>{`This started with me wanting to keep my skills sharp and for some fun. I decided to participate in the development challenge that Scotch.io was running every week. After the completion of building a countdown timer I wanted to push my skills and build something useful and fun. Thus I wanted to create my own version of a pomodoro timer.`}</p>
    <h3>{`Desired Result`}</h3>
    <p>{`Successfully participate in the Scotch.io challenge and create a working pomodoro timer based on their starting point. Then take that knowledge and create my own version using Vue and host it.`}</p>
    <h4>{`Challenge Starter`}</h4>
    <p><img parentName="p" {...{
        "src": "https://toripugh.com/images/gallery/pomodoro//pomodoro-scotchio.jpg",
        "alt": "Challenge Timer"
      }}></img></p>
    <h4>{`My Pomodoro Timer`}</h4>
    <p><img parentName="p" {...{
        "src": "https://toripugh.com/images/gallery/pomodoro/pomodoro-original.jpg",
        "alt": "Regular Pomodoro Timer"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://toripugh.com/images/gallery/pomodoro/pomodoro-tomatillo.jpg",
        "alt": "Tomatillo Rest Period"
      }}></img></p>
    <p>{`The design for this was purely tongue and cheek. Pomodoro is italian for tomato so it should be red. For the rest periods I thought it should be a 'green tomato' or 'tomatillo' and made the theme green. Something cute and different, a slight change of pace from just being red all the time.`}</p>
    <p>{`I have a display for what stage your in. This clearly has the 5 stages — Timer, Rest, Timer, Rest, and Timer. It then tells you which stage your currently in and highlights the marker. I also fixed an issue with the original timer that it wouldn't clear the time between restarts. This caused it to gain speed on every restart and countdown into obliviion.`}</p>
    <p>{`I've also added sound effects to mark the change of the rounds. This was an interesting part for me as I'd never done anything with sounds embedded in anything. It adds that extra level of professionalism and helps if you've moved to another program or another tab.`}</p>
    <p>{`For this version I did not make the app in CodePen, I used the Vue CLI and exported it out and uploaded it to Github Pages. This was it's on mini adventure but luckily wasn't nearly as tough as I thought it would be.`}</p>
    <p>{`I wrote a technical `}<a parentName="p" {...{
        "href": "/blog/building-a-pomodoro-timer-with-vuejs-on-codepen"
      }}>{`blog post`}</a>{` about creating this app.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      